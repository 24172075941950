<mat-form-field appearance="fill" class="w-full">
    <mat-label class="text-[16px] font-medium tracking-[-0.32px]">
      <span>{{label}}</span>
  </mat-label>
  <div class="absolute top-[-25px] right-0">
    <div class="div">
      <span class="font-normal text-[#1065B4] cursor-pointer"
      (click)="removeField()" *ngIf = "showRemove">
      - <span class="underline">Remove</span></span>
    </div>
    <div class="div">
      <span class="font-normal text-[#1065B4] cursor-pointer"
      (click)="addAnother()" *ngIf = "showAnother">
      + <span class="underline">Another</span></span>
    </div>
    
  </div>
    <input
      matInput
      PhoneValidator
      PhoneNumberFormat
      [placeholder]="placeholder"
      [formControl]="control"/>
    <mat-error *ngIf="control?.hasError('invalidPhone')">
      {{ errorMessage }}
    </mat-error>
    <mat-error
    *ngIf="control?.hasError('required')">This
    field is
    required</mat-error>
  </mat-form-field>