import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { RouterOutlet } from '@angular/router';
import { User } from 'app/core/user/user.types';
import { AppConstants } from 'app/core/constants/app.constants';

@Component({
  selector: 'onboardflow02-layout',
  standalone: true,
  imports: [CommonModule, FuseLoadingBarComponent,RouterOutlet],
  templateUrl: './onboardflow02.component.html',
  styleUrl: './onboardflow02.component.scss'
})
export class OnboardFlow02LayoutComponent {

  user: User;
  
  userName: string;

  ngOnInit() {
     this.user = JSON.parse(localStorage.getItem(AppConstants.USER_AUTH));
        this.userName = `${this.user.firstname} ${this.user.lastname}`;
  }

}
