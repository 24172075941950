import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Directive({
  selector: '[PhoneNumberFormat]',
  standalone: true
})
export class PhoneNumberFormatDirective implements OnInit {
  private observer: MutationObserver | null = null;
  private maxLength = 11;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value) {
      this.formatPhoneNumber(input.value, input.value.length);
    }
    this.observeDisabledState();
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    
    // Get cursor position
    let cursorPos = input.selectionStart || 0;
    
    // Extract raw digits and limit to 11 characters
    let rawValue = input.value.replace(/\D+/g, '');
    
    // If max length exceeded, restore previous state
    if (rawValue.length > this.maxLength) {
      input.value = this.formatPhoneNumber(rawValue.slice(0, this.maxLength), input.value.length);
      input.setSelectionRange(this.maxLength, this.maxLength);
      return;
    }

    this.formatPhoneNumber(rawValue, cursorPos);
  }

  @HostListener('blur')
  onBlur(): void {
    this.formatAndSetInput();
  }

  private formatAndSetInput(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let cursorPos = input.selectionStart || 0;
    let rawValue = input.value.replace(/\D+/g, '').slice(0, this.maxLength);
    this.formatPhoneNumber(rawValue, cursorPos);
  }

  private formatPhoneNumber(value: string, previousCursorPos: number): string {
    const input = this.el.nativeElement as HTMLInputElement;
  
    // Get count of digits before the previous cursor position
    let rawDigitsBeforeCursor = input.value.slice(0, previousCursorPos).replace(/\D+/g, '').length;
  
    let formattedValue = value.length <= 6 ? value : new AsYouType('US').input(value);
  
    this.renderer.setProperty(input, 'value', formattedValue);
  
    // Ensure cursor position does not move unexpectedly
    let newCursorPos = formattedValue.length; // Default to end of input
    let digitCount = 0;
  
    for (let i = 0; i < formattedValue.length; i++) {
      if (/\d/.test(formattedValue[i])) {
        digitCount++;
      }
  
      if (digitCount === rawDigitsBeforeCursor) {
        newCursorPos = i + 1;
        break;
      }
    }
  
    // Prevent cursor from jumping to the start
    if (previousCursorPos > formattedValue.length) {
      newCursorPos = formattedValue.length;
    }
  
    // Restore cursor position
    setTimeout(() => {
      input.setSelectionRange(newCursorPos, newCursorPos);
    });
  
    return formattedValue;
  }
  

  private observeDisabledState(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    this.observer = new MutationObserver(() => {
      setTimeout(() => {
        if (input.disabled && input.value) {
          this.formatPhoneNumber(input.value, input.value.length);
        }
      }, 500);
    });

    this.observer.observe(input, { attributes: true, attributeFilter: ['disabled'] });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
