<ng-container *ngIf="name || number">
    <a class="flex items-center">
        <div class="flex flex-0 items-center justify-center border rounded-full grid-avatar overflow-hidden"
        [appTooltip]="showTooltip ? tooltipData : null" [showMoreInfo]="showMoreInfoInTooltip">
            <ng-container *ngIf="imageUrl">
                <img [ImageViewer]="showImageViewer?imageUrl:null"
                    class="object-cover w-full h-full rounded-full"
                    [src]="imageUrl"
                    alt="Contact avatar"/>
            </ng-container>
            <ng-container *ngIf="!imageUrl">
                <div  class="flex items-center justify-center w-full h-full rounded-full uppercase light-bg">
                    {{(name && name != ' ') ? (name | shortName) : 'UN'}}
                </div>
            </ng-container>
        </div>
        <div class="min-w-0 max-w-52 ml-2">
            <div class="text-base font-medium leading-5 truncate">{{name}}</div>
            <div class="leading-5 truncate font-light text-base" *ngIf="number && showNumber">{{ number | phoneNumber }}</div>
        </div>
    </a>
</ng-container>
