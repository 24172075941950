import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { MatIconModule } from '@angular/material/icon';
import { SubSink } from 'subsink';
import { FireMessagingService } from 'app/core/services/fire-messaging.service';
import { CallNotificationService } from '../../services/call-notification.service';
import { SipOutboundFunctionService } from '../../services/sip-outbound-function.service';
import { SipConnectionService } from '../../services/sip-connection.service';
import { RegistererState } from 'sip.js';
import { ToastrService } from 'ngx-toastr';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { forEach, zip } from 'lodash';
import _ from 'lodash';
import { CallCenterService } from '../../services/call-center.service';
import { lastValueFrom } from 'rxjs';
import { GFColumnTypes } from 'app/core/models/grid-filter.models';
import { DidsService } from '../../services/dids.service';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { CommonService } from 'app/core/services/common.service';
import { AppEvent } from 'app/core/models/common.models';
import { environment } from 'environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'active-call-badge',
  standalone: true,
  imports: [CommonModule, ShortNamePipe, MatIconModule, PhoneNumberPipe,MatTooltipModule],
  templateUrl: './active-call-badge.component.html',
  styleUrl: './active-call-badge.component.scss'
})


export class ActiveCallBadgeComponent implements OnInit {

  public declineTone = new Audio();

  private subs: SubSink = new SubSink();

  public fileURL = environment.imgURL;

  constructor(public inboundFnService: SipInboundFunctionService,
    public callcenterShared: CallCenterSharedService,
    public sipInboundFnService: SipInboundFunctionService,
    public fireMsgService: FireMessagingService,
    public callNotificationService: CallNotificationService,
    private sipconnectionFn : SipConnectionService,
    private sipOutboundService : SipOutboundFunctionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private didService: DidsService,
    private commonService : CommonService
  ) {

  }

  ngOnInit(): void { 
    this.setCallInfo(); 
    this.subs.sink = this.fireMsgService.pushNotification.subscribe((val) => {
      console.log("pushNotification", val)
      if (val?.data) {
        if ((val.data.call_id?.length > 0 || val.data.conference_call_id?.length > 0) && val.data.type == 'CALL') {

          console.log('notificationAction', val.data.notificationAction)
          if (val.data?.action == "start_ring") {

            // const isSipDisconnected: boolean = !this.callcenterShared.connection ||
            // this.callcenterShared.connection?.state != 'Started'

            console.warn('sip connection state on start ring',this.sipconnectionFn.isConnected())

            if(!this.sipconnectionFn.isConnected())
              {
                try {
                      this.sipconnectionFn.initialiseConnection().then((state) => {
            
                      if (state === RegistererState.Registered) {

                        this.sipInboundFnService.inviteCall();
                    
                        console.warn('registered from incoming push')
                        // this.callcenterShared.setConnectionTimeout();
        
                      }
                      else
                      {
                        this.toastr.error('failed to connecting sip.')
                      }
                      })    
                    }
                    catch (e) {
                      this.toastr.error('Failed to initialize sip connection');
                    }
              }
     
            console.log('callnotifypush', val.data)
            const notificationAction = val.data.notificationAction? val.data.notificationAction : 'push'
            val.data.isInitialLoad = val.data?.isInitialLoad?val.data?.isInitialLoad : false

            if((notificationAction == 'push' && val.data.isInitialLoad == false) ||  (val.data.isInitialLoad == true))
              {
                if(!this.callcenterShared.callQ.find(x => x.callDetails.call_id == val.data.call_id))
                  {
                    this.pushtoIncomingCallQ(val,notificationAction)
                    console.log("%c Added to callQ", "color: green;", this.callcenterShared.callQ);
                    
                    console.warn('sip connection state', this.callcenterShared.connection?.state)
                    this.callNotificationService.callNotificationData.next(val.data)
                    this.callcenterShared.popupOpenStatus.next(true) 
                  }  
              }
            if(notificationAction == 'connect' && val.data.isInitialLoad == false)
              {
                this.callcenterShared.stopRing()
                const callerSession = this.callcenterShared.initialCallQ.find(x => x.callDetails.call_id == val.data.call_id)
                if(callerSession)
                  {
                    // callerSession.session.state = 'connect'
                    // this.callcenterShared.updateCallQ(callerSession,'inbound')
                    this.sipInboundFnService.oncallConnect(callerSession)
                  }
                 
              }  
              else if(notificationAction == 'connect' && val.data.isInitialLoad == true)
                {
                  this.callcenterShared.notificationForCall = true
                }  
              if(notificationAction == 'reject')  
                {
                  console.warn('call rejected from notification')
                 
                  if(!this.callcenterShared.initialCallQ)
                    {
                      this.sipInboundFnService.callDeclineTone()
                      this.callcenterShared.stopRing()        
                    }
                 
                  const callerSession = this.callcenterShared.initialCallQ.find(x => x.callDetails.call_id == val.data.call_id)
                  if(callerSession)
                    {
                      this.callcenterShared.removeFromCallQ(val.data.call_id)
                    }

                }
            }
            if(val.data?.action == "stop ring")
              {
                
                if(!this.callcenterShared.verifyCallIdAutoAccept(val.data.call_id))//to verify call attended not by this agent
                  {          
                    this.callcenterShared.removeFromCallQ(val.data.call_id)   
                    console.warn('call removed at stop ring')  
                  } 
                else if (this.callcenterShared.holdCallQ.find(x => x.callDetails.call_id == val.data.call_id)){
                  this.callcenterShared.removeFromCallQ(val.data.call_id)   
                  console.warn('hold call removed at stop ring')  
                }
                else
                  {
                    console.log('stopring')
                    this.callcenterShared.stopRing()
                  }     
              }
            if(val.data?.action == "outbound call answered"){
              const callerSession = this.callcenterShared.callQ.find(x => x.customValues.callType == 'outbound' )
              if(callerSession){
                callerSession.callDetails.is_redirected_to_voicemail = val?.data?.is_redirected_to_voicemail || 'false';
                if(val.data?.is_redirected_to_voicemail == "true"){
                  this.sipOutboundService.endCall(callerSession);
                }else if(callerSession.callDetails.parent_call_id == null){
                  callerSession.callDetails.parent_call_id = val.data.call_id
                  let isCallOnHold = callerSession.customValues.isCallOnHold;
                  // this.sipOutboundService.getCurrentCallId(callerSession,true,false,true)
                  this.sipOutboundService.getCurrentCallId(callerSession,!isCallOnHold,isCallOnHold,true)
                }   
              }
            }

            if(val.data?.action == "outbound call completed") 
              {
                //*In transferred outbound call, there is no parent_call_id field, so check for call_id.
                const hold_callerSession = this.callcenterShared.holdCallQ.find(x => ((x.callDetails.parent_call_id == val.data.call_id) || (x.callDetails.call_id == val.data.call_id)));
                if(hold_callerSession)
                  {
                    let holdedCallId: string;
                    if(hold_callerSession.customValues.callType == "outbound"){
                      holdedCallId = hold_callerSession.callDetails.parent_call_id;
                    }else if(hold_callerSession.customValues.callType == "inbound"){
                      holdedCallId = hold_callerSession.callDetails.call_id;
                    }
                    this.callcenterShared.removeByParentCallId(holdedCallId)
                  }
              }
            if(val.data?.action == "CONFERENCE_JOIN")
              {
                this.callcenterShared.setConferenceCallsList(val.data);
              }
            if(val.data?.action == "CONFERENCE_LEAVE")
              {
                this.callcenterShared.removeFromConferenceCallsList(val.data);
              }
            
        }
        else if(val.data.type == 'ACTIVE_CALL_ACTION'){
          const callerSession = this.callcenterShared.callQ.find(x => x.callDetails.call_id == val?.data?.data?.call_id)
          if(val.data.action == "hold" || val.data.action == "unhold"){
            this.holdCall(callerSession);
          }
          else if(val.data.action == "end"){
            this.endCall(callerSession);
          }
        }
        
      }
    })
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.preventDefault();
      if(!this.activeCallerSession)
      {
        this.openDialer();
      } 
    }
  }
  
  setCallInfo(){
    let callq = JSON.parse(localStorage.getItem("CallInfo")) || [];
    let inbound_callq = callq.filter((call)=> call.customValues.callType == "inbound")
    let oubound_callq = callq.filter((call)=> call.customValues.callType != "inbound")
    if(inbound_callq?.length > 0 ) {
      this.processEntries(inbound_callq);
    }
    if(oubound_callq?.length > 0){
      this.toastr.warning("The outgoing call has been ended...")
      oubound_callq.map((call)=> {this.callcenterShared.updateCallInfoInLocalStorage(call, true)})
    }
    this.callcenterShared._callQ.subscribe((data) =>{
      console.log("dattattatatatata", data)
      this.updateSessionInLocalStorage(data)
    }) 
  }

  async processEntries(inbound_callq): Promise<void> {
    const promises = inbound_callq.map(async call => {
      try {
        let option = { filters: [
          { colname: 'sid', condition: 'is', value: call.callDetails.call_id, operator: 'AND', type: GFColumnTypes.string }
        ]};
        const response = await lastValueFrom(this.didService.getCallReport(option));
        if(response.data?.result?.length && response.data.result[0]["status"] != "completed")
          return call;
        else
          return null;
      } 
      catch (error) {
        return null;
      }
    });

    const results = await Promise.all(promises);
    inbound_callq = results.filter(entry => entry !== null);

    // Now you can process the result array
    this.setCallTimeOnReload(inbound_callq);
    if(this.callcenterShared.initialCallQ.length > 0 || this.callcenterShared.activeCallerSession || this.callcenterShared.holdCallQ.length>0){
      this.callcenterShared.popupOpenStatus.next(true)
      if(this.callcenterShared.initialCallQ.length > 0)
        this.callcenterShared.ring();
      if(this.activeCallerSession)
        this.activeCallerSession.session.state = null;
    }
  }

  setCallTimeOnReload(callQ){
    let current_time = new Date().getTime()
    callQ.forEach((_call)=>{
      _call.customValues.call_answered = false;
      let checkout_diff = current_time - _call.customValues.callStartTime
      _call.customValues.checkoutTime =Math.ceil( checkout_diff / 1000 )
      if(_call.customValues.notification_established == false && _call.customValues.callType == "inbound"){
        _call.checkouttimer = null
        this.callcenterShared.checkCallTimeout(_call);
      }
      if(_call.customValues.callConnectTime != null){
        let elapsed_diff = current_time - _call.customValues.callConnectTime
        _call.customValues.elapsedTime = Math.ceil(elapsed_diff / 1000)
        if(_call.customValues.isCallOnHold){
          _call.timer = null
          this.callcenterShared.startCallTimer(_call);
        }
      }
    })
    this.callcenterShared.callQ = callQ
  }

  updateSessionInLocalStorage(data){
    let temp_array = []
    data.forEach((call)=>{
      let local_session = _.merge({}, call)
      local_session["session"] = {"state":local_session?.session?.state};
      temp_array.push(local_session)
    })
    localStorage.setItem("CallInfo", JSON.stringify(temp_array))
  }
  
  pushtoIncomingCallQ(val:any,status)
  {
    this.sipInboundFnService.pushtoCallQ({callDetails:val.data,session:{state:status}})
    if(status != 'connect')
      {
        this.callcenterShared.ring()
      }

  }


  openCallCenter() 
  { 
    this.callcenterShared.popupOpenStatus.next(true)
  }

  openDialer() 
  {
    const isOpen = this.callcenterShared.popupOpenStatus.getValue()
    this.callcenterShared.showDialer = !isOpen // to identify user clicked dialer or not
    this.callcenterShared.popupOpenStatus.next(!isOpen)
    this.closeCommonSidePanel()
  }

  closeCommonSidePanel()
  {
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.ClosePanel}
    this.commonService.dispatch(new AppEvent(AppEventType.CommonPanelEvent, sidePanelConstants));
  }

  public get activeCallerSession() {
    return this.callcenterShared.activeCallerSession // this.sipInboundFnService.selectedCaller
  }

  /**
 * 
 * mute ongoing call
 */

  muteCall() {

    this.activeCallerSession.customValues.isMuted = !this.activeCallerSession.customValues.isMuted
    this.callcenterShared.muteCall(this.activeCallerSession)
  }

  endCall(callerSession) {
    if(callerSession.customValues.callType == 'inbound')
    {
      this.sipInboundFnService.endCall(callerSession)
    }
    else if(callerSession.customValues.callType == 'outbound')
    {
      this.sipOutboundService.endCall(callerSession)
    }
  }

  holdCall(callerSession)
  {
    if(callerSession.customValues.callType == 'inbound')
      {
        this.sipInboundFnService.holdCall(callerSession)
      }
    else if(callerSession.customValues.callType == 'outbound')
      {
        this.sipOutboundService.holdCall(callerSession, true)
      }
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }


  formatTime(seconds: number): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    if (hrs > 0) {
      return `${hrs}h:${mins}m:${secs}s`; // Format for hours, minutes, and seconds
    } else if (mins > 0) {
      return `${mins}m:${secs}s`; // Format for minutes and seconds
    } else {
      return `${secs}s`; // Only seconds
    }
  }

  isNullorEmpty(value: string | null): boolean {
    return value === null || value === "" || value === undefined;
  }


  // private pad(value: number): string {
  //   return value < 10 ? '0' + value : value.toString();
  // }

}
