<div class="flex items-center mt-3">
    <div class="tab-switch-container">
        <a class="tab-switch grow" [ngClass]="{'active':selectedTab=='agent'}" (click)="tabOnClick('agent')">Users</a>
        <a class="tab-switch grow" [ngClass]="{'active':selectedTab=='contact'}" (click)="tabOnClick('contact')">Contacts</a>
    </div>
    <img class="flex w-8 h-8 mx-2 cursor-pointer" src="../../../../../assets/icons/call-center/dial-icon-circle.svg" alt="Key-Pad"
    (click)="tabOnClick('dialer')">
</div>

<section [hidden]="selectedTab != 'agent'">
    <dialer-agent-list [session]="callerSession" [type]="'merge'" (closeTransferList)="closeTransferList.emit()"></dialer-agent-list>
</section>
<section [hidden]="selectedTab != 'contact'" class="mt-2">
    <div class="relative mx-auto h-10 border border-gray-300 rounded-md shadow-sm">
        <input #searchInput name="searchText" class="py-2 pl-11 font-light focus:outline-none"
        placeholder="Search contact"  [formControl]="searchControl">
        <div  matRipple class="search-icon text-xl max-h-10 absolute inset-y-0  flex items-center px-2 text-gray-70 focus:outline-none focus:border-blue-500">
          <img src="../../../../assets/icons/search-normal.svg" class="w-5"/>
        </div>
        <button mat-icon-button *ngIf="searchControl.value" class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-70 focus:outline-none" (click)="clearSearch()">
            <mat-icon svgIcon="heroicons_mini:x-mark"></mat-icon>
        </button>
    </div>
    <contact-search [searchControl]="searchControl" [isMergeCallList]="true" [session]="callerSession"></contact-search>
</section>

<section *ngIf="selectedTab == 'dialer'">
    <outbound-dialer #dialerComp [isUsedForMergeCall]="true" [showHeader]="false" [callBtnText]="'Merge'" (inputChange)="onDialerValueChange($event)"></outbound-dialer>
</section>