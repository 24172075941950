@if (layout === 'empty') {
    <empty-layout></empty-layout>
}
@else if(layout === 'onboard')
{
    <onboardflow02-layout></onboardflow02-layout>
}
@else {
    <classic-layout></classic-layout>
}


