import { Routes } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';

export const EXT_ONBOARD_ROUTES: Routes = [
    {
        path: '',
        data: {
            layout: 'onboard'
        },
        loadChildren: () => import('../../modules/onboard/onboard.routes').then(c=>c.default)
    }
];