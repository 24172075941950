import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { ToolTipDirective } from 'app/shared/directives/tool-tip.directive';
import { ContactInfoCard } from '../contact-info-card/contact-info-card.component';
import { ImageViewerDirective } from 'app/shared/directives/image-viewer.directive';

@Component({
  selector: 'profile-details',
  standalone: true,
  imports: [CommonModule, PhoneNumberPipe, ShortNamePipe, ToolTipDirective, ImageViewerDirective],
  templateUrl: './profile-details.component.html',
  styleUrl: './profile-details.component.scss'
})
export class ProfileDetailsComponent {

  @Input() id: number;

  @Input() imageUrl: string;

  @Input() name: string;

  @Input() number: string;

  @Input() email: string;

  @Input() showNumber: boolean = true;

  @Input() showTooltip: boolean = false;

  tooltipData: ContactInfoCard = new ContactInfoCard();

  @Input() showMoreInfoInTooltip: boolean = false;

  @Input() showImageViewer: boolean = false;

  ngOnInit(){
    if(this.showTooltip){
      this.tooltipData.id = this.id;
      this.tooltipData.name = this.name;
      this.tooltipData.image = this.imageUrl;
      this.tooltipData.number = this.number;
      this.tooltipData.email = this.email;
    }
  }

}
