import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PhoneValidatorDirective } from 'app/shared/directives/phone-validator.directive';
import { PhoneNumberFormatDirective } from 'app/shared/directives/phone-number-format.directive';

@Component({
  selector: 'phone-input-control',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule,MatInputModule, 
    PhoneValidatorDirective,PhoneNumberFormatDirective],
  templateUrl: './phone-input-control.component.html',
  styleUrl: './phone-input-control.component.scss'
})
export class PhoneInputControlComponent {

  @Input() label: string = '';

  @Input() placeholder: string = '';

  @Input() control: FormControl = new FormControl();
  
  @Input() errorMessage: string = 'Please enter a valid phone number';

  @Output() onRemove = new EventEmitter<void>();

  @Output() onAddAnother = new EventEmitter<void>();

  @Input() showAnother: boolean = false;

  @Input() showRemove: boolean = false;

  removeField() {
    
    this.onRemove.emit();
  }

  addAnother()
  {
    this.onAddAnother.emit();
  }
  

}
