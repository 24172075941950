import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AppConstants } from 'app/core/constants/app.constants';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const _router: Router = inject(Router);
    const _authservice: AuthService = inject(AuthService);

    // Check the authentication status
    if (!_authservice.isLoggedIn) {
        _router.navigate(['sign-in']);
        return false;
    }

    if(state.url == '/phone-system/sms-signup')
    {
         if(localStorage.getItem(AppConstants.IS_CAMPAIGN_REGISTERED) == 'true'){
            return false;
         }
    }

    //check for account deletion url
    if (state.url =='/delete-account') {
        // Redirect to the users/edit route
        _router.navigate([`/org/users/${_authservice.userId}/delete-account`]);
        return false;
    }
    return true;
};
