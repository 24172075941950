import { Injectable } from '@angular/core';
import { AppSharedConstants } from 'app/core/constants/app-shared.constants';
import { from, Observable, of, switchMap } from 'rxjs';
import { decodeDataWithToken, encodeDataWithToken } from '../functions/encription.utils';
import { AppConstants } from 'app/core/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class IndexDbHttpService {

  private indexDBName = AppSharedConstants.INDEXDB_NAME;

  private dbConnection: IDBDatabase | null = null;

  constructor(
    // private dbService: NgxIndexedDBService
  ) {
  }
  createObjectStore(storeName: string, key: string = 'id', isAutoIncrement: boolean = true): Promise<void> {

    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      const newVersion = currentVersion + 1;
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, newVersion);

        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: key, autoIncrement: isAutoIncrement });
          }
        };

        request.onsuccess = () => {
          resolve();
        };

        request.onerror = (event) => {
          reject('Failed to create object store');
        };
      });
    });
  }

  addUpdateMetaStore(storeName: string, item: any): Promise<any> {

    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise<any>((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const transaction = db.transaction([storeName], 'readwrite');
          const store = transaction.objectStore(storeName);
          const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
          const encryptedItem = { storeName: item.storeName, encryptedData };

          const putRequest = store.put(encryptedItem);

          putRequest.onsuccess = () => {
            resolve('Record updated successfully');
          };

          putRequest.onerror = () => {
            reject('Failed to update record');
          };
        };
      });
    });
  }

  updateRecord(storeName: string, item: any): Promise<any> {
    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise<any>((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const transaction = db.transaction([storeName], 'readwrite');
          const store = transaction.objectStore(storeName);
          const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
          let putRequest = null;
          if (item.id) {
            const encryptedItem = { id: item.id, encryptedData };
            putRequest = store.put(encryptedItem);
          }
          else {
            putRequest = store.put({ encryptedData });
          }


          putRequest.onsuccess = () => {
            resolve('Record updated successfully');
          };

          putRequest.onerror = () => {
            reject('Failed to update record');
          };
        };

        request.onerror = () => {
          reject('Failed to open database for update');
        };
      });
    });
  }

  openDatabase(): Promise<IDBDatabase> {

    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          resolve((event.target as IDBOpenDBRequest).result);
        };

        request.onerror = (event) => {
          reject('Database failed to open: ' + (event.target as IDBOpenDBRequest).error);
        };
      });
    })

  }
  checkStoreExists(storeName: string): Promise<boolean> {
    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.indexDBName, currentVersion);

        request.onsuccess = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const storeExists = db.objectStoreNames.contains(storeName);
          db.close();
          resolve(storeExists);
        };

        request.onerror = () => {
          reject('Failed to open database');
        };
      });
    })
  }
  
  getCurrentDatabaseVersion(): Promise<number> {
    return new Promise((resolve, reject) => {
      if (this.dbConnection) {
        resolve(this.dbConnection.version);
        return;
      }

      const request = indexedDB.open(this.indexDBName);

      request.onsuccess = (event) => {
        this.dbConnection = (event.target as IDBOpenDBRequest).result;
        console.log('dbversion - ' + this.dbConnection.version);
        resolve(this.dbConnection.version);
        this.closeDatabase();
      };

      request.onerror = () => {
        reject('Failed to open database');
      };
    });
  }

  closeDatabase() {
    if (this.dbConnection) {
      this.dbConnection.close();
      this.dbConnection = null;
    }
  }

  getByKey<T>(storename: string, key: any): Observable<T> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        return from(new Promise<T>((resolve, reject) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            const transaction = db.transaction([storename], 'readonly');
            const store = transaction.objectStore(storename);
            const getRequest = store.get(key);

            getRequest.onsuccess = () => {
              if (getRequest.result) {
                resolve(decodeDataWithToken(getRequest.result.encryptedData, localStorage.getItem(AppConstants.AUTH_TOKEN)));
              }

            };

            getRequest.onerror = (event: any) => {
              reject('Error retrieving data from store');
            };
          };

          request.onerror = (event: any) => {
            reject('Error opening database');
          };
        }));
      })
    );
  }
  getCountOfRecords(storeName: string): Observable<number> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        // Open the database with the current version before updating
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const countRequest = store.count();

            countRequest.onsuccess = () => {
              observer.next(countRequest.result);
              observer.complete();
            };
            countRequest.onerror = () => {
              observer.error('Failed to count records in the store');
            };
          };
          request.onerror = () => {
            observer.error('Failed to open database');
          };
        });
      })
    );
  }

  add(storeName: string, item: any): Observable<number> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        // Open the database with the current version before updating
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            let addRequest = null;
            const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
            if (item.id) {
              const encryptedItem = { id: item.id, encryptedData };
              addRequest = store.add(encryptedItem);
            }
            else {
              addRequest = store.add({ encryptedData });
            }
            //const addRequest = store.add(item);
            addRequest.onsuccess = () => {
              observer.next(addRequest.result);
              observer.complete();
            };
            addRequest.onerror = (event) => {
              observer.error('Failed item add to store');
            };
          };
          request.onerror = (event) => {
            observer.error('Failed to open database for add');
          };
        });
      })
    );
  }
  bulkAdd(storeName: string, items: any[]): Observable<number[]> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        // Open the database with the current version before updating
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const ids: number[] = [];
            items.forEach(item => {
              let addRequest = null;
              const encryptedData = encodeDataWithToken(item, localStorage.getItem(AppConstants.AUTH_TOKEN))
              if (item.id) {
                const encryptedItem = { id: item.id, encryptedData };
                addRequest = store.add(encryptedItem);
              }
              else {
                addRequest = store.add({ encryptedData });
              }

              // store.add(item); // store.add('item', item.id);
              addRequest.onsuccess = () => {
                ids.push(addRequest.result as number);
                if (ids.length === items.length) {
                  observer.next(ids);
                  observer.complete();
                }
              };
            });
          };
          request.onerror = (event) => {
            observer.error('Failed to open database for bulk add');
          };
        });
      })
    );
  }
  delete(storeName: string, id: number): Observable<any> {
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        return new Observable<any>((observer) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const deleteRequest = store.delete(id);

            deleteRequest.onsuccess = () => {
              observer.next(`Item with id ${id} deleted successfully`);
              observer.complete();
            };

            deleteRequest.onerror = () => {
              observer.error(`Failed to delete item with id ${id}`);
            };
          };

          request.onerror = () => {
            observer.error('Failed to open database for delete operation');
          };
        });
      })
    );
  }
  clearAllTables(dbName: string): Promise<void> {
    return this.getCurrentDatabaseVersion().then((currentVersion) => {
      return new Promise((resolve, reject) => {
        const newVersion = currentVersion + 1;
        const request = indexedDB.open(dbName, newVersion);

        request.onupgradeneeded = (event: any) => {
          const db = event.target.result;

          if (db.objectStoreNames.length === 0) {
            console.warn('No object stores found in the database.');
            resolve();
            return;
          }

          for (const storeName of db.objectStoreNames) {
            db.deleteObjectStore(storeName);
          }
        };

        request.onsuccess = (event: any) => {
          const db = event.target.result;
          db.close();
          resolve();
        };

        request.onerror = (event: any) => {
          console.error('Error opening database:', event);
          reject(event.target.error);
        };
      });
    });
  }
  deleteDatabase() {
    // Close any open connections to the database
    this.closeDatabase();

    const DBDeleteRequest = window.indexedDB.deleteDatabase(this.indexDBName);

    DBDeleteRequest.onblocked = (event) => {
      console.warn("Error message: Database in blocked state.");
    };

    DBDeleteRequest.onerror = (event) => {
      console.warn("Error deleting database.");
    };

    DBDeleteRequest.onsuccess = (event) => {
      console.warn("Database deleted successfully");

      // Optionally, you can add logic here to reset the UI state or reload the page
      // location.reload();  // Reload the page if you want to reinitialize the app state
    };
  }


  getFilteredDataFromLocalDB(
    storename: string,
    option: any = {},
    filterOffset: number = 0,
    filterLimit: number = 0,
    filterObjectName: string = null,
    localFilter_Option: any = null,
  ): Observable<any[]> {

    if (localFilter_Option) {
      option.filters = localFilter_Option;
    }
    console.log('optioninlocdbfilter', option);
    return from(this.getCurrentDatabaseVersion()).pipe(
      switchMap((currentVersion) => {
        return from(new Promise<any[]>((resolve, reject) => {
          const request = indexedDB.open(this.indexDBName, currentVersion);

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            const transaction = db.transaction([storename], 'readonly');
            const store = transaction.objectStore(storename);
            const getAllRequest = store.getAll();

            getAllRequest.onsuccess = () => {
              let dbData = getAllRequest.result;

              // Decrypt the data before proceeding
              dbData = dbData.map(item => {
                try {
                  // Decrypt the 'encryptedData' field
                  const token = localStorage.getItem(AppConstants.AUTH_TOKEN)
                  const decryptedData = decodeDataWithToken(item.encryptedData, token);
                  return { ...decryptedData, id: item.id }; // Keep the ID and decrypted data
                } catch (error) {
                  console.error('Error decrypting data:', error);
                  return null;
                }
              }).filter(item => item !== null); // Remove null values caused by decryption errors

              if (option.sort && option.sort.length > 0) {
                const sort = option.sort[0];
                if (sort.inner_column && sort.colname === 'date_created') {
                  dbData.sort((a, b) => {
                    if(a[sort.inner_column] == null || b[sort.inner_column] == null) return 0;
                    const valA = new Date(a[sort.inner_column].date_created).getTime();
                    const valB = new Date(b[sort.inner_column].date_created).getTime();
                    if (isNaN(valA) || isNaN(valB)) {
                      console.error("Invalid date format in data: ", { dateA: a.date_created, dateB: b.date_created });
                      return 0; // Do not sort if dates are invalid
                    }
                    if (sort.direction === 'asc') {
                      return valA - valB; // Ascending order
                    } else if (sort.direction === 'desc') {
                      return valB - valA; // Descending order
                    }
                    return 0; // Default case, no sorting
                  })
                }
                else if (sort.colname === 'date_created') {
                  // Handle sorting by date_created
                  dbData.sort((a, b) => {
                    const dateA = new Date(a.date_created).getTime();
                    const dateB = new Date(b.date_created).getTime();

                    // Ensure both dates are valid
                    if (isNaN(dateA) || isNaN(dateB)) {
                      console.error("Invalid date format in data: ", { dateA: a.date_created, dateB: b.date_created });
                      return 0; // Do not sort if dates are invalid
                    }

                    if (sort.direction === 'asc') {
                      return dateA - dateB; // Ascending order
                    } else if (sort.direction === 'desc') {
                      return dateB - dateA; // Descending order
                    }
                    return 0; // Default case, no sorting
                  });
                } else if (sort.direction === 'asc') {
                  // Sort by `id` first
                  dbData.sort((a, b) => a.id - b.id);

                  // Sort by the column value (considering both strings and numbers)
                  dbData.sort((a, b) => {
                    const valA = a[sort.colname];
                    const valB = b[sort.colname];

                    if (typeof valA === 'string' && typeof valB === 'string') {
                      return valA.toLowerCase().localeCompare(valB.toLowerCase());
                    } else if (typeof valA === 'number' && typeof valB === 'number') {
                      return valA - valB;
                    } else {
                      return String(valA).localeCompare(String(valB));
                    }
                  });
                } else if (sort.direction === 'desc') {
                  // Sort by `id` first
                  dbData.sort((a, b) => b.id - a.id);

                  // Sort by the column value (considering both strings and numbers)
                  dbData.sort((a, b) => {
                    const valA = a[sort.colname];
                    const valB = b[sort.colname];

                    if (typeof valA === 'string' && typeof valB === 'string') {
                      return valB.toLowerCase().localeCompare(valA.toLowerCase());
                    } else if (typeof valA === 'number' && typeof valB === 'number') {
                      return valB - valA;
                    } else {
                      return String(valB).localeCompare(String(valA));
                    }
                  });
                }
              }
          

              // Apply filters if specified
              if (option.filters && option.filters.length > 0) {
                dbData = dbData.filter(item => {
                  return option.filters.every(filter => {
                    if (filter.conditions) {
                      // Apply OR/AND logic within conditions
                      const orConditions = filter.conditions.filter(cond => cond.operator === 'OR');
                      const andConditions = filter.conditions.filter(cond => cond.operator !== 'OR');

                      const andResult = andConditions.every(cond => this.applyFilterCondition(item, cond));
                      const orResult = orConditions.some(cond => this.applyFilterCondition(item, cond));

                      return (andConditions.length === 0 || andResult) && (orConditions.length === 0 || orResult);
                    } else {
                      // Apply single condition if no nested conditions
                      if (filterObjectName) {
                        return this.applyFilterCondition(item[filterObjectName], filter);
                      } else {
                        return this.applyFilterCondition(item, filter);
                      }
                    }
                  });
                });
              } else if (!option.filters) {

                  dbData = dbData.filter(item => {
                    if(storename != 'chat_msg'){
                    return Object.keys(option).every(key => {
              
                        if (item.hasOwnProperty(key)) {
                          if (typeof option[key] === 'string') {
                            // For string-based filters, check if the item contains the value
                            return item[key]?.toLowerCase().includes(option[key]?.toLowerCase());
                          } else {
                            // For non-string filters, check equality
                            return item[key] === option[key];
                          }
                        } else {
                          return true;
                        }           
                    });
                  }
                  else
                  {
                    if(item.module_type == 'Message')
                    {
                      if(option.sort)
                      {
                        delete option.sort
                      }
                      return Object.keys(option).every(key => {
                        if (item.hasOwnProperty(key)) {
                            if (typeof option[key] === 'string') {
                              // For string-based filters, check if the item contains the value
                              return item[key]?.toLowerCase().includes(option[key]?.toLowerCase());
                            } else {
                              // For non-string filters, check equality
                              return item[key] === option[key];
                            }
                          }
                           
                      })

                    }
                    else if(item.module_type == 'Fax')
                          {
                            if(item.is_inbound == false && item?.to_number == option.users_number) //if outbound fax
                            {
                                  return true;
                            }
                            else if(item.is_inbound == true && item?.from_number == option.users_number)// if inbound fax
                            {
                                  return true;
                            }    
                            else
                            {
                              return false;
                            }

                          }
                        else if(item.module_type == 'Call'){
                          if(item.direction == 'outbound' && item.formated_to_number == option.users_number)
                          {
                            return true;
                          }
                          else if(item.direction == 'inbound' && item.formated_from_number == option.users_number)
                          {
                            return true;
                          }
                        }
                          else
                          {
                            return false;
                          }
                  }
                  });
                
                // else
                // {
                //   return this.filterChatMessages(dbData, option);
                // }
            
              }

              // Apply pagination (offset and limit)
              resolve(dbData.slice(filterOffset, filterOffset + filterLimit));
            };

            getAllRequest.onerror = (event: any) => {
              reject('Error retrieving data from store');
            };
          };

          request.onerror = (event: any) => {
            reject('Error opening database');
          };
        }));
      })
    );
  }
  filterChatMessages(dbData: any, option: any): Observable<any[]> {
    const filteredData = dbData.filter(item => {
      return Object.keys(option).every(key => {
        if (item.hasOwnProperty(key)) {
          
          if (typeof option[key] === 'string') {
            return item[key]?.toLowerCase().includes(option[key]?.toLowerCase());
          } else {
            return item[key] === option[key];
          }
        } else {
          return true;
        }
      });
    });
  
    return of(filteredData); // Return the filtered data as an observable
  }

  private applyFilterCondition(item: any, conddet: any): boolean {
    if (item != null) {
      let values = item[conddet.colname];
      const filterValue = conddet.value?.toString().toLowerCase();

      if (!filterValue || filterValue.length === 0) {
        return false;
      }

      if (!Array.isArray(values)) {
        values = [values]; // Convert single value to an array for uniform processing
      }

      return values.some(value => {
        const strValue = value?.toString().toLowerCase();
        if (!strValue) return false;

        switch (conddet.condition) {
          case 'equalto':
            return strValue === filterValue;
          case 'contains':
            return strValue.includes(filterValue);
          case 'startswith':
            return strValue.startsWith(filterValue);
          default:
            return false;
        }
      });
    }
    return false;
  }

}

// interface HasId {
//   id: number; // You can adjust the type of 'id' as necessary, e.g., string or number
// }

