<section id="contact-list mt-1" class="mt-2">
    <div class="flex justify-between">
        <div class="w-full" #contacts (scroll)="onContactScroll(contacts)" [ngClass]="{'overflow-hidden pr-2':isLoadingMore, 'overflow-y-auto overflow-x-hidden': !isLoadingMore,
                                                                                        'contacts-dialer-list':isMergeCallList,'contacts':!isMergeCallList}">
            @if(rows?.length > 0 && !isLoading){
            <ng-container *ngFor="let row of rows; trackBy: trackByFn; let last = last; let i = index">
                <!-- (click)="callNumber"  -->
                <ng-container  *ngIf="!isMergeCallList; else mergeCallTemplate">
                    <a class="flex items-center py-2 cursor-pointer item
                        animate__animated animate__fadeIn hover:bg-gray-50" [ngClass]="{'border-b':!last}"
                        InitiateCall [callType]="'contact'" [toNumber]="row.mobile || row.phone"
                        [agentsipuri]="agentSipUri">
                        <div class="flex-0 items-center justify-center w-11 h-11 border p-[3px] rounded-full ml-1.5" (mouseenter)="onAvatarMouseEnter()" (mouseleave)="onAvatarMouseLeave()"
                            [appTooltip]="{ id: row.id, name: row.first_name+' '+(row.last_name?row.last_name:''), image: row.image ? fileUrl+row.image : null, email: row.email, number: row.mobile || row.phone}"
                            [showMoreInfo]="true">
                            <ng-container *ngIf="row?.image">
                                <img class="w-full h-full rounded-full object-cover" [src]="fileUrl+row.image"
                                    alt=""/>
                            </ng-container>
                            <ng-container *ngIf="!row?.image">
                                <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase
                                bg-gray-200 text-gray-600">
                                    @if(row.first_name){
                                    {{row.first_name | shortName}}
                                    }@else {
                                    UN
                                    }
                                </div>
                            </ng-container>
                        </div>
                        <div class="flex flex-col flex-auto min-w-0 ml-2">
                            <div class="flex flex-row text-center items-center">
                                <div class="font-medium text-base leading-[22px] truncate tracking-[-0.32px]">
                                    {{row.first_name+' '+(row.last_name?row.last_name:'')}}
                                </div>
                            </div>
                            <div class="flex flex-row">
                                <div *ngIf="row.mobile || row.phone" class="text-base font-light leading-[22px] truncate">{{(row.mobile || row.phone) | phoneNumber: false}}</div>
                                <div *ngIf="!row.mobile && !row.phone"
                                    class="text-base font-light leading-[22px] truncate">No phone number
                                </div>
                            </div>
                        </div>
                        <!-- * this div is to find the index of element enterd to viewport -->
                        <div appVisibilityCheck (visibilityChange)="onVisibilityChange($event, i)"></div>
                    </a>
                </ng-container>
                <ng-template #mergeCallTemplate>
                    <a class="flex items-center py-2 cursor-pointer item
                    animate__animated animate__fadeIn hover:bg-gray-50" [ngClass]="{'border-b':!last}" (click)="mergeCall(row)">
                        <div class="flex-0 items-center justify-center w-11 h-11 border p-[3px] rounded-full ml-1.5">
                            <ng-container *ngIf="row?.image">
                                <img class="w-full h-full rounded-full object-cover" [src]="fileUrl+row.image"
                                    alt=""/>
                            </ng-container>
                            <ng-container *ngIf="!row?.image">
                                <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase
                                bg-gray-200 text-gray-600">
                                    @if(row.first_name){
                                    {{row.first_name | shortName}}
                                    }@else {
                                    UN
                                    }
                                </div>
                            </ng-container>
                        </div>
                        <div class="flex flex-col flex-auto min-w-0 ml-2">
                            <div class="flex flex-row text-center items-center">
                                <div class="font-medium text-base leading-[22px] truncate tracking-[-0.32px]">
                                    {{row.first_name+' '+(row.last_name?row.last_name:'')}}
                                </div>
                            </div>
                            <div class="flex flex-row">
                                <div *ngIf="row.mobile || row.phone" class="text-base font-light leading-[22px] truncate">{{(row.mobile || row.phone) | phoneNumber: false}}</div>
                                <div *ngIf="!row.mobile && !row.phone"
                                    class="text-base font-light leading-[22px] truncate">No phone number
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center rounded-full mr-2 select-none shrink-0">
                            <img src="../../../../../assets/icons/call-center/repeat-circle-merge.svg" alt="">
                        </div>
                        <!-- * this div is to find the index of element enterd to viewport -->
                        <div appVisibilityCheck (visibilityChange)="onVisibilityChange($event, i)"></div>
                    </a>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="currentCount < totalCount">
                <div class="flex pt-1 pb-2 px-1.5 mt-[5px] w-full">
                    <div class="self-center">
                        <ngx-skeleton-loader count="1" appearance="circle" class="flex"
                            [theme]="{height:'44px', width:'44px','background':'#e1e1e1','margin':'0px'}" />
                    </div>
                    <div class="w-full ml-2">
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'75%','margin-bottom': '0px'}" />
                        </div>
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'50%','margin-bottom': '0px'}" />
                        </div>
                    </div>
                </div>
            </ng-container>

            }@else if (isLoading){
            <!-- skeleton template -->
            <ng-container *ngFor="let repeat of [1, 2, 3, 4, 5, 6]">
                <div class="flex pt-1 pb-2 px-1.5 mt-[5px] border-b w-full">
                    <div class="self-center">
                        <ngx-skeleton-loader count="1" appearance="circle" class="flex"
                            [theme]="{height:'44px', width:'44px','background':'#e1e1e1','margin':'0px'}" />
                    </div>
                    <div class="w-full ml-2">
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'75%','margin-bottom': '0px'}" />
                        </div>
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'50%','margin-bottom': '0px'}" />
                        </div>
                    </div>
                </div>
            </ng-container>
            }
            @else{
            <div class="w-full h-full flex items-center justify-center">
                <p class="text-base text-slate-500">No contacts to display.</p>
            </div>
            }
        </div>
        
        <div class="alphabet-bar botomSide" #alphabetBar (scroll)="onAlphabetScroll(alphabetBar)" *ngIf="!searchControl.value && !isLoading"
            [ngClass]="{'alphabet-dialer-height':isMergeCallList, 'alphabet-height':!isMergeCallList}">
            <ng-container *ngFor="let char of alphabets; let first=first; let last=last">
                <div class="letter" [ngClass]="{'active':selectedLetter==char,
                                                'pt-1':first, 'pb-1':last}"
                    (click)="alphabetOnClick(char)" [attr.data-letter]="char">{{char}}</div>
            </ng-container>
        </div>
    </div>
</section>