<section id="contact-list mt-1" class="call-history mt-2" #history (scroll)="onScroll(history)" [ngClass]="loadingMore ? 'overflow-hidden pr-2' : 'overflow-y-auto overflow-x-hidden'">
    @if(rows?.length > 0 && !isLoading){
        <ng-container *ngFor="let row of rows; trackBy: trackByFn">
            <!-- (click)="callNumber(getNumbertoCall(row))" -->
            <a class="flex items-center py-2 cursor-pointer border-b item
            animate__animated animate__fadeIn hover:bg-gray-50"  InitiateCall
            [callType]="'contact'" [toNumber]="getNumbertoCall(row) | phoneNumber: false" 
            [agentsipuri]="agentSipUri">
                <div class="flex-0 items-center justify-center w-11 h-11 border rounded-full p-[3px]" 
                (mouseenter)="onAvatarMouseEnter()" (mouseleave)="onAvatarMouseLeave()"
                [appTooltip]="{name: getName(row),  number: getNumbertoCall(row) | phoneNumber: false, image: getImage(row)}" 
                [showMoreInfo]="false">
                    @if(getImage(row)){
                    <ng-container>
                        <img
                            class="w-full h-full rounded-full object-cover"
                            [src]="getImage(row)"
                            alt="Contact avatar"/>
                    </ng-container>
                    }
                    @else{
                        <ng-container>
                            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase
                             bg-gray-200 text-gray-600">
                             {{getName(row) | shortName}}
                            </div>
                        </ng-container>
                    }
                        
                    
    
                </div>

                <div class="flex flex-col flex-auto min-w-0 ml-2">
                    <div class="flex flex-row ">
                        <div class="contact-name truncate">
                            {{getName(row)}}
                        </div>
                    </div>
                    <div class="flex flex-row">
                        @if(row.created_user == userId){
                            <div class="truncate phone">{{row.call_to | phoneNumber: false}}</div>
                        }
                        @else if(row.call_accepted_by == userId) {
                            <div class="truncate phone">{{row.call_from | phoneNumber: false}}</div>
                        }
                        @else if(row.is_missed_call) {
                            <div class="truncate phone">{{row.call_from | phoneNumber: false}}</div>
                        }
                        @else{
                            <div class="truncate phone">{{row.call_from | phoneNumber: false}}</div>
                        }
                        
                    </div>
                </div>

                <div class="flex call-info-container p-2" [ngClass]="{
                    'bg-inbound': row.call_accepted_by==userId,
                    'bg-missed': row.is_missed_call || (row.call_accepted_by!=userId && row.created_user!=userId && !row.is_missed_call),
                    'bg-outgoing' : row.created_user==userId
                    }">

                    @if(row.created_user == userId){
                        <img class="dir-img"  src="../../../../../assets/icons/call-center/call-outgoing black.svg" alt="">
                    }
                    @else if(row.call_accepted_by == userId) {
                        <img  class="dir-img"  src="../../../../../assets/icons/call-center/call-incoming.svg" alt="">
                    }
                    @else if(row.is_missed_call) {
                        <img  class="dir-img"  src="../../../../../assets/icons/call-center/call-missed.svg" alt="">
                    }
                    @else{
                        <img  class="dir-img"  src="../../../../../assets/icons/call-center/call-missed.svg" alt="">
                    }

                    <span class="call-info">
                        @if(row.call_accepted_by==userId){
                            <span class="direction inbound">Inbound</span>
                        }@else if(row.created_user==userId) {
                            <span class="direction outbound">Outbound</span>
                        }
                        @else if(row.is_missed_call){
                            <span class="direction missed">Missed</span>
                        }
                        @else{
                            <span class="direction missed">Missed</span>
                        }
                        {{ row.date_created | date:'MM/dd/yyyy h:mm a'}}
                    </span>
                </div>
            </a>
        </ng-container>
        <ng-container *ngIf="currentCount < totalCount">
            <div class="flex pt-1 pb-2 px-0.5 mt-[5px] w-full">
                <div class="self-center">
                    <ngx-skeleton-loader count="1" appearance="circle" class="flex"
                        [theme]="{height:'44px', width:'44px','background':'#e1e1e1','margin':'0px'}" />
                </div>
                <div class="w-full ml-2 mt-[5px]">
                    <div>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'60%','margin-bottom': '0px'}" />
                    </div>
                    <div>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'35%','margin-bottom': '0px','margin-right': '5px'}" />
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'58%','margin-bottom': '0px'}" />
                    </div>
                </div>
            </div>
        </ng-container>
    <!-- <ng-container >
        <button *ngIf="showScrollButton" (click)="scrollToTop()" class="flex rounded-lg bg-sky-200 p-1.5 absolute bottom-8 right-9 animate__animated animate__slideInUp">
            <mat-icon svgIcon="heroicons_outline:arrow-up" class="icon-size-5" aria-hidden="false"></mat-icon>
        </button>
    </ng-container> -->
    }@else if(isLoading){
        <!-- skeleton template -->
        <ng-container *ngFor="let repeat of [1, 2, 3, 4, 5, 6]">
            <div class="flex pt-1 pb-2 px-0.5 mt-[5px] border-b w-full">
                <div class="self-center">
                    <ngx-skeleton-loader count="1" appearance="circle" class="flex"
                        [theme]="{height:'44px', width:'44px','background':'#e1e1e1','margin':'0px'}" />
                </div>
                <div class="w-full ml-2 mt-[5px]">
                    <div>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'60%','margin-bottom': '0px'}" />
                    </div>
                    <div>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'35%','margin-bottom': '0px','margin-right': '5px'}" />
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'background':'#e1e1e1',height:'15px',width:'58%','margin-bottom': '0px'}" />
                    </div>
                </div>
            </div>
        </ng-container>
    }
    @else{
        <div class="w-full h-full flex items-center justify-center">
            <p class="text-base text-slate-500">No history available.</p>
        </div>
    }
</section>



