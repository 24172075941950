<div class="div"   *transloco="let t;scope:'callcenter'">
    <div
    *ngIf="!showKeypad">
     <div *ngIf="callerSession" [@fadeInOut] class="fade-in-out-div">
     <section id="ongoing-call">
         <div class="flex mb-2 items-center">
            <div class="timer" [ngClass]="{'bg-[#1065B4]': callerSession?.callDetails?.trasfered_by_user_id,
                                          'connecting':callerSession.session?.state != 'Established',
                                          'bg-[#7743DB]':callerSession?.customValues?.isMergedCall}">
                <span class="text-sm font-medium">{{formatTime(callerSession.customValues?.elapsedTime)}}</span>
            </div>
             <label for="" class="font-semibold text-base ml-2 flex items-center">
                @if(callerSession?.callDetails?.trasfered_by_user_id){
                    {{t('callcenter.OngoingTransferredCall')}}
                }@else if(callerSession?.customValues?.isMergedCall){
                    {{t('callcenter.OngoingMergedCall')}}
                }
                @else if(callerSession.session?.state == 'Established' && callerSession.customValues.call_answered) {
                    {{t('callcenter.OngoingCall')}}
                }
                @else if(callerSession.session?.state == 'Established' && callerSession.session?.state != 'Establishing') {
                    {{t('callcenter.Calling')}}
                }@else {
                    <mat-icon svgIcon="feather:loader" class="animate-spin icon-size-4 mr-0.5"></mat-icon>
                    {{t('callcenter.Connecting')}}
                }
                
            </label>
         </div>
         <div class="mb-[6px] max-h-56 overflow-auto" 
            *ngIf="_callcenterSharedService.conferenceCalldetail && ((_callcenterSharedService.conferenceCalldetail?.call_id == callerSession.callDetails.parent_call_id)||(_callcenterSharedService.conferenceCalldetail?.call_id == callerSession.callDetails?.call_id))">
             <ng-container *ngFor="let call of _callcenterSharedService.conferenceCalldetail.data; let last = last">
                 <div class="flex justify-between items-center py-1" [ngClass]="{'border-b-[1.5px] border-[#5d6e8a1a]':!last}">
                    <profile-details [name]="call.name||'Unknown'" [imageUrl]="call.image_url ? fileURL + call.image_url : '/assets/icons/call-center/avatar.svg' "
                        [showNumber]="true" [number]="call.agent_sip||call.company_user_number"></profile-details>
                    <span class="text-base font-normal tracking-[-0.32px] text-[#1065B4] hover:underline cursor-pointer"
                        (click)="removeCallFromConference(call)">Remove</span>
                 </div>
             </ng-container>
         </div>
         <div class="ongoing-container" [ngClass]="{'transferred-call': callerSession?.callDetails?.trasfered_by_user_id,
                'merged-call': callerSession?.customValues?.isMergedCall}">
            <div *ngIf="callerSession?.callDetails?.transfered_by_user_name" class="flex justify-center mb-3">
                <div class="bg-white rounded-lg px-2.5 py-1.5 flex items-center justify-center w-fit text-base font-medium leading-[22px] transfer-title">
                    <img src="../../../../../assets/icons/call-center/repeat-circle-transfer.svg" alt="" class="select-none w-[22px]">
                    <span class="truncate">{{t('callcenter.TransferredFrom')}} {{callerSession?.callDetails?.transfered_by_user_name}}</span>
                </div>
            </div>
            <!-- <div *ngIf="callerSession?.customValues?.isMergedCall && callerSession?.customValues?.isAgentConferenceCall" class="flex justify-center mb-3">
                <div class="bg-white rounded-lg px-2.5 py-1.5 flex items-center justify-center w-fit text-base font-medium leading-[22px] transfer-title">
                    <img src="../../../../../assets/icons/call-center/repeat-circle-merge.svg" alt="" class="select-none w-[22px] mb-[-3px]">
                    <span class="truncate">{{t('callcenter.From')}} 
                        {{callerSession?.callDetails?.company_users_name.trim() || (callerSession?.callDetails?.customer_number_in_merge | phoneNumber: false)}}
                    </span>
                </div>
            </div> -->
             <div class="flex items-center pr-1 pl-1">
                 <div class="h-13 w-13 rounded-full mr-1 flex items-center justify-center text-lg bg-white border p-[3px] shrink-0">
                    <div class="flex items-center justify-center w-full h-full rounded-full text-xl uppercase bg-gray-200">
                        @if(callerSession?.callDetails?.company_users_image)
                        {
                            <img
                            class="w-full h-full rounded-full object-cover"
                            [src]="fileURL+callerSession?.callDetails?.company_users_image"
                            alt="Contact avatar"/>
                        }
                        @else if(callerSession?.callDetails?.call_to_image)
                        {
                            <img
                            class="w-full h-full rounded-full object-cover"
                            [src]="fileURL+callerSession?.callDetails?.call_to_image"
                            alt="Contact avatar"/>
                        }
                        
                        @else{
                            @if(callerSession?.customValues?.callType == 'inbound'){
                                @if(callerSession?.customValues?.isAgentCall || callerSession?.customValues?.isAgentOutboundTransferCall || callerSession?.customValues?.isAgentConferenceCall)
                                {
                                    @if(isNullorEmpty(callerSession?.callDetails?.agent_name)){
                                        UN
                                    }
                                    @else{
                                        {{callerSession.callDetails?.agent_name | shortName}}
                                    }
                                }
                                @if(!callerSession?.customValues?.isAgentCall && !callerSession?.customValues?.isAgentOutboundTransferCall && !callerSession?.customValues?.isAgentConferenceCall)
                                {
                                    @if(isNullorEmpty(callerSession.callDetails?.company_users_name)){
                                        UN
                                    }
                                    @else{
                                        {{callerSession.callDetails?.company_users_name | shortName}}
                                    }
                                }
                            }
                            @else if(callerSession?.customValues?.callType == 'outbound'){
                                @if(callerSession?.customValues?.isAgentCall)
                                {
                                    @if(isNullorEmpty(callerSession.callDetails?.to_sip_user?.name)){
                                        UN
                                    }
                                    @else{
                                        {{callerSession.callDetails?.to_sip_user?.name | shortName}}
                                    }
                                }
                                @if(!callerSession?.customValues?.isAgentCall)
                                {
                                    @if(isNullorEmpty(callerSession.callDetails?.users_name)){
                                        UN
                                    }
                                    @else{
                                        {{callerSession.callDetails?.users_name | shortName}}
                                    }
                                }
                            
                            }
                            
                        }
                     
                    </div>
                 </div>
                 <div class="flex flex-col">
                    <!-- Name -->
                        @if(callerSession?.customValues?.callType == 'inbound'){
                            @if(callerSession.customValues.isAgentCall || callerSession?.customValues?.isAgentOutboundTransferCall || callerSession?.customValues?.isAgentConferenceCall){
                                <p class="pl-2 font-normal text-base">
                                    {{isNullorEmpty(callerSession?.callDetails?.agent_name)? 'Unknown':
                                    callerSession?.callDetails?.agent_name | titlecase}} </p>
                            }@else if(callerSession.customValues.isCustomerCall || callerSession.customValues.isCustomerOutboundTransferCall){
                                <p class="pl-2 font-normal text-base">
                                    {{isNullorEmpty(callerSession.callDetails?.company_users_name)? 'Unknown':
                                    callerSession.callDetails?.company_users_name | titlecase}}</p>
                            }

                        }@else if(callerSession?.customValues?.callType == 'outbound'){
                            @if(callerSession.customValues.isAgentCall){
                                <p class="pl-2 font-normal text-base" *ngIf="callerSession?.customValues?.callType == 'outbound'">
                                    {{isNullorEmpty(callerSession.callDetails?.to_sip_user?.name)? 'Unknown':
                                    callerSession.callDetails?.to_sip_user?.name | titlecase}}</p>
                            }@else if(!callerSession.customValues.isAgentCall){
                                <p class="pl-2 font-normal text-base">
                                    {{isNullorEmpty(callerSession.callDetails?.users_name)? 'Unknown':
                                    callerSession.callDetails?.users_name | titlecase}}</p>
                            }
                        }

                     <div class="flex items-center pr-1 pl-1">
                        <!-- recoding badge -->
                        <div *ngIf="callerSession?.callDetails?.call_recording_enabled=='True'"
                            class="h-6 w-6 rounded-full mr-1.5 flex items-center justify-center border-[#F700001A] border-[2px] p-[1px]">
                            <div class="flex items-center justify-center w-3 h-3 rounded bg-[#F70000] animate-pulse"></div>
                        </div>
                        <!-- Phone number -->
                        <label class="phone-number font-semibold">
                            @if(callerSession?.customValues?.callType == 'inbound'){
                                @if(callerSession.customValues.isAgentCall || callerSession?.customValues?.isAgentConferenceCall){
                                    {{callerSession?.callDetails?.from_number.split('@')[0]}}
                                }@else if(callerSession?.customValues.isCustomerCall){
                                    {{callerSession?.callDetails?.from_number?.length > 6 ? (callerSession?.callDetails?.from_number | phoneNumber: false) : callerSession?.callDetails?.from_number}}
                                }@else if(callerSession?.customValues.isAgentOutboundTransferCall){
                                    {{callerSession?.callDetails?.to_number.split('@')[0]}}
                                }@else if(callerSession.customValues.isCustomerOutboundTransferCall){
                                    {{callerSession?.callDetails?.to_number?.length > 6 ? (callerSession?.callDetails?.to_number | phoneNumber: false) : callerSession?.callDetails?.to_number}}
                                }
                            }
                            @else if(callerSession?.customValues?.callType == 'outbound'){
                                @if(callerSession.customValues.isAgentCall){
                                    {{callerSession?.customValues?.callTo}}
                                }
                                @else{
                                    {{callerSession?.callDetails.users_number  | phoneNumber: false}}
                                }
                            }
                        </label>
                    </div>
                 </div>
                 <div class="ml-auto shrink-0">
                    <img class="icon-call-end ml-auto call-end-active"
                    src="../../../../../assets/icons/call-center/call-end.svg" alt="Mute"
                    (click)="onEndCall()">
                </div>
             </div>
         </div>
    </section>
    <section id="actions">
        <!-- Call action buttons like mute, hold etc -->
        <div class="action-container text-sm font-medium mt-[2px] select-none" 
            [ngClass]="{'set-border-radius': callerSession?.customValues?.isAgentCall || callerSession?.customValues.isAgentOutboundTransferCall || callerSession?.customValues?.isAgentConferenceCall,
                        'transferred-call': callerSession?.callDetails?.trasfered_by_user_id,
                        'merged-call': callerSession?.customValues?.isMergedCall}">
            <div class="flex rounded-lg" [ngClass]="!callerSession?.customValues?.isAgentCall&&!callerSession?.customValues?.isMergedCall?'justify-between':'justify-center gap-3'">
                <div class="action flex flex-col justify-center items-center action-box" 
                    [ngClass]="{'action-active':this.callerSession.customValues.isMuted}">
                    <img class="flex action-icon" src="../../../../../assets/icons/call-center/mute.svg" alt="Mute" (click)="onMuteCall()" 
                        [ngClass]="{'action-disable': callerSession.session?.state != 'Established'}">
                        <p class=""
                        for="">{{t('callcenter.Mute')}}</p>
                </div>
                <div *ngIf="!callerSession?.customValues?.isMergedCall" class="action flex flex-col justify-center items-center action-box" 
                    [ngClass]="{'action-active':this.callerSession.customValues.isCallOnHold}">
                     <img class="flex action-icon" src="../../../../../assets/icons/call-center/pause.svg" alt="Hold" (click)="onHoldCall()" 
                        [ngClass]="{'action-disable': callerSession.session?.state != 'Established' || callerSession?.customValues?.isMergedCall}">
                        <p class=""
                        for="">{{t('callcenter.Hold')}}</p>
                 </div>
                 <div *ngIf="!callerSession?.customValues?.isAgentCall" class="action flex flex-col justify-center items-center action-box"
                    [ngClass]="{'action-active':_callcenterSharedService?.showMergeList}">
                    <img class="flex action-icon" src="../../../../../assets/icons/call-center/merge-icon.svg" alt="Merge" (click)="onMergeClick()"
                        [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established' || callerSession?.customValues?.isAgentCall}">
                       <p class="" for="">{{t('callcenter.Merge')}}</p>
                </div>
                <div *ngIf="!callerSession?.customValues?.isAgentCall && !callerSession?.customValues?.isMergedCall" class="action flex flex-col justify-center items-center action-box"
                    [ngClass]="{'action-active':inboundFnService?.showTransferList}">
                    <img class="flex action-icon" src="../../../../../assets/icons/call-center/transfer.svg" alt="Transfer" (click)="transfer()"
                        [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established' || callerSession?.customValues?.isMergedCall || callerSession?.customValues?.isAgentCall}">
                        <p class="" for="">{{t('callcenter.Transfer')}}</p>
                </div>
                <div *ngIf="!callerSession?.customValues?.isAgentCall" class="action flex flex-col justify-center items-center action-box"  (click)="onShowKeypad()">
                    <img class="flex action-icon" src="../../../../../assets/icons/call-center/dial-icon-circle.svg" alt="Key Pad" 
                     [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established'}">
                        <p class="" for="">{{t('callcenter.Dialpad')}}</p>
                </div>
            </div>
        </div>
         <!-- footer buttons -->
        <div *ngIf="!callerSession?.customValues?.isAgentCall && !callerSession?.customValues.isAgentOutboundTransferCall && !callerSession?.customValues?.isAgentConferenceCall" class="additional-action-container select-none" 
            [ngClass]="{'transferred-call': callerSession?.callDetails?.trasfered_by_user_id,
                        'merged-call': callerSession?.customValues?.isMergedCall}">
            <button *ngIf="callerSession?.callDetails?.users_id" class="footer-button text-sm font-semibold tracking-[-0.32px] leading-[20px]" (click)="showMoreInfo(callerSession?.callDetails)">
                <i class="icon-profile-circle flex items-center text-[20px] mr-1"></i>
                {{t('callcenter.MoreInfo')}}
            </button>
            <button *ngIf="!callerSession?.callDetails?.users_id" class="footer-button text-sm font-semibold tracking-[-0.32px] leading-[20px]" (click)="addContact(getPhoneNumber())">
                <i class="icon-profile-circle flex items-center text-[20px] mr-1"></i>
                {{t('callcenter.AddContact')}}
            </button>
            <button [disabled]="!getPhoneNumber()" InitiateChat class="footer-button text-sm font-semibold tracking-[-0.32px] leading-[20px]"
                [toNumber]="getPhoneNumber()">
                <i class="icon-messages-2 flex items-center text-[20px] mr-1"></i>
                {{t('callcenter.Message')}}
            </button>
        </div>
    </section>
 </div>
</div>
<ng-container *ngIf="showKeypad">
<key-pad (closeKeypad)="onCloseKeypad()"
[session] = "callerSession"
></key-pad>
</ng-container>

<ng-container *ngIf="inboundFnService?.showTransferList">
    <dialer-agent-list [session]="callerSession" [type]="'transfer'" (closeTransferList)="inboundFnService.showTransferList=false"></dialer-agent-list>
</ng-container>

<ng-container *ngIf="_callcenterSharedService?.showMergeList">
     <agent-and-contact-list [callerSession]="callerSession" (closeTransferList)="_callcenterSharedService.showMergeList=false"></agent-and-contact-list>
</ng-container>
</div>

