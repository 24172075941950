<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full p-10">

    <div class="flex justify-between mb-2">
        <div class="flex">
            <img class="h-10 w-10"  src="../../../../assets/images/logo/logo.svg" alt="">
            <div class="text-2xl font-bold ml-2">Simplyfuse</div>
        </div>
        

        <div class="flex flex-col">
            <div class="text-lg font-bold">{{userName}}</div>
            <div class="text-sm font-medium text-[#246AD4] cursor-pointer text-right">Logout</div>
        </div>
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required her  e for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>
