import { Injectable } from '@angular/core';
import { CallCenterSharedService } from './call-center-shared.service';
import { BehaviorSubject, retry} from 'rxjs';
import { CallCenterService } from './call-center.service';
import { SubSink } from 'subsink';
import { CallNotification } from '../models/call-center.models';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CallNotificationService {

  public subs: SubSink = new SubSink();

  public callNotificationData = new BehaviorSubject(new CallNotification())
  public callNotificationData$ = this.callNotificationData.asObservable();

  constructor(
    private callcenterSharedService: CallCenterSharedService,
    private toastr: ToastrService,
    private callCenterService:CallCenterService) { }

 
  connectCallOnInitialLoad(retryCount:number = 0)
  {

        setTimeout(() => {

          console.warn('call connetct try-'+retryCount)
   

          var call_details = this.callNotificationData.getValue()
          var isAgentCall = call_details?.call_type == "agent_to_agent_inbound"?true:false
         console.log('connectcalldetails', call_details)
         let payload = {
     
           call_sid: call_details.call_id,
           to_number: call_details.to_number,
           sip_username: 'sip:' + this.callcenterSharedService.sipDetails.sip_uri,
           call_center_enabled: true,
           project_id: call_details.project_id,
           from_number:isAgentCall == true?call_details.from_number:null,
           call_type  :call_details?.call_type,
           call_recording_enabled: call_details?.call_recording_enabled || false
         };
          
         this.callcenterSharedService.setAutoAcceptableCallIds(call_details.call_id)
     
         this.subs.sink = this.callCenterService.connectToCall(payload).subscribe((response) => {

          const statusCode = response.status; 

           if(statusCode == 200)
             {
                console.log('connect call called successfully')
             }
             else
             {
               if(retryCount<=2)
                 {
                    retryCount++
                    this.connectCallOnInitialLoad(retryCount)
                 }
               else
               {
                 this.toastr.error('Either the call is ended or '+
                   'someone else from your team is attended the call', 'Unable to connect to the call')
                 this.removeCallFailed(call_details.call_id)
               }
      
             }
         },error=>{
          if(retryCount<=2)
             {
                 retryCount++
                 this.connectCallOnInitialLoad(retryCount)
             }
           else
             {
               this.toastr.error('Either the call is ended or '+
                 'someone else from your team is attended the call', 'Unable to connect to the call')
               this.removeCallFailed(call_details.call_id)
             }
            
         })
          
        }, retryCount * 1000);

  }
  removeCallFailed(callid)
  {
    this.callcenterSharedService.removeFromCallQ(callid)
  }

  checkAndclearNotifyOnIncoming(incomingCallid:string)
  {
    if(this.callNotificationData.getValue().call_id)
    {
      if(incomingCallid == this.callNotificationData.getValue().call_id)
      {
        console.log('onclear')
        this.callNotificationData.next(new CallNotification())
      }
    }

  }

  closeNotification(tag: string, callId?: string) {
    console.warn('closemessage_ sending')
    // if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    //   navigator.serviceWorker.controller.postMessage({
    //     action: 'closeNotification',
    //     tag: tag
    //   });
    //   console.warn('closemessage_sendedto_sw - ',tag)
    // }
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.getNotifications({ tag: tag }).then(notifications => {
          notifications.forEach(notification => {
              notification.close();
          });
        });
        registration.getNotifications({ tag: "Call-Notification-"+callId }).then((notifications) => {
          notifications.forEach(notification => {
            notification.close();
          });
        });
      });
    });

  }
  // pushToCallq(callDet:any)
  // {
  //   this._callQ.next(callDet);
  // }
}
