import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[focus]',
  standalone:true
})
export class FocusDirective implements OnInit {
  @Input('focus') shouldFocus: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.shouldFocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 0); // Delay to ensure the DOM is fully loaded
    }
  }
}
