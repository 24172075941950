import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialerAgentListComponent } from '../dialer-agent-list/dialer-agent-list.component';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';
import { ContactSearchComponent } from 'app/modules/tenant-customers/components/contact-search/contact-search.component';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OutboundDialerComponent } from '../outbound-dialer/outbound-dialer.component';

@Component({
  selector: 'agent-and-contact-list',
  standalone: true,
  imports: [CommonModule, DialerAgentListComponent, ContactSearchComponent, MatIconModule, ReactiveFormsModule,
    OutboundDialerComponent
  ],
  templateUrl: './agent-and-contact-list.component.html',
  styleUrl: './agent-and-contact-list.component.scss'
})
export class AgentAndContactListComponent {

  @ViewChild(OutboundDialerComponent) dialerComp:OutboundDialerComponent;

  @Input() callerSession: any;

  @Output() closeTransferList = new EventEmitter<void>(); 

  searchControl = new FormControl();

  selectedTab: 'agent'|'contact'|'dialer' = 'agent';

  constructor(public inboundFnService: SipInboundFunctionService){}

  ngOnInit(){
    
  }

  clearSearch(){
    this.searchControl.setValue('');
  }  

  onDialerValueChange(value){
    console.log('value from dial pad',value);
    
  }

  tabOnClick(tab:'agent'|'contact'|'dialer'){
    if(this.selectedTab == 'dialer' && tab != 'dialer'){
      this.dialerComp.clearInputAndLastDialledNumber();
    }
    this.selectedTab = tab;
  }

  ngOnDestroy(){
    this.dialerComp?.clearInputAndLastDialledNumber();
  }

}
